import { styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import { getBillingDataServer } from "../../../redux/actions/stripeActions";

import PaymentSuccess from "./PaymentSuccess";
import OrderSummary from "./OrderSummary";
import Checkout from "./Checkout";
import { CheckoutSteps } from "../../../types/settings";
import { getUser } from "../../../redux/reducers/usersReducer";
import { ActivePlan, NewActivePlan } from "../Plans";
import PaymentInProcess from "./PaymentInProcess";
import PaymentFail from "./PaymentFail";

interface Props {
  activePlan?: NewActivePlan;
  handleChangeStep: (step?: CheckoutSteps) => void;
  activeStep?: CheckoutSteps;
  permanentPlan?: ActivePlan;
  currentPlan?: ActivePlan;
}

const PlansRightSidebar = ({ activePlan, activeStep, handleChangeStep, permanentPlan, currentPlan }: Props) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const handleCheckoutClick = () => handleChangeStep(CheckoutSteps.checkout);

  const handleBackClick = () => handleChangeStep(CheckoutSteps.orderSummary);

  const handlePayClick = () => {
    handleChangeStep(CheckoutSteps.waitingForPayment);
  };

  useEffect(() => {
    if (!user) return;
    dispatch(getBillingDataServer());
  }, [user]);

  useEffect(() => {
    handleChangeStep();
  }, [activePlan]);

  const getStep = {
    [CheckoutSteps.orderSummary]: (
      <OrderSummary
        activePlan={activePlan}
        permanentPlan={permanentPlan}
        handleCheckoutClick={handleCheckoutClick}
        currentPlan={currentPlan}
      />
    ),
    [CheckoutSteps.checkout]: (
      <Checkout handleBackClick={handleBackClick} handlePayClick={handlePayClick} activePlan={activePlan} />
    ),
    [CheckoutSteps.waitingForPayment]: <PaymentInProcess handleChangeStep={handleChangeStep} activePlan={activePlan} />,
    [CheckoutSteps.failure]: <PaymentFail handleBackClick={handleBackClick} activePlan={activePlan} />,
    [CheckoutSteps.success]: <PaymentSuccess />,
  };

  return <Wrapper>{getStep[activeStep || CheckoutSteps.orderSummary]}</Wrapper>;
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "56px 24px",
  minWidth: "322px",
  width: "322px",
  borderLeft: `1px solid ${theme.palette.grey[100]}`,
  overflow: "hidden auto",
  paddingBottom: "200px",

  "&::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "16px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
    border: " 4px solid #fff",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  [theme.breakpoints.down("lg")]: {
    minWidth: "292px",
    width: "292px",
  },

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export default PlansRightSidebar;
