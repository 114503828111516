import React, { FC } from "react";
import Switch from "../../../components/Switch/Switch";
import { styled, Typography } from "@mui/material";
import { Button } from "@maestro-org/ui-kit";

interface Props {
  isAnnual?: boolean;
  handleChange: (isAnnual: boolean) => () => void;
}

const SubscriptionPlansSubtitle: FC<Props> = ({ isAnnual, handleChange }) => {
  return (
    <Wrapper>
      <SwitchWrapper>
        <MonthlyButton isAnnual={isAnnual} onClick={handleChange(false)} color={isAnnual ? "primary" : "secondary"}>
          <SwitchText>Monthly</SwitchText>
        </MonthlyButton>
        <AnnualButton isAnnual={isAnnual} onClick={handleChange(true)} color={isAnnual ? "secondary" : "primary"}>
          <SwitchText>Annual</SwitchText>
          <SaveWrapper>
            <SaveText>SAVE 10%</SaveText>
          </SaveWrapper>
        </AnnualButton>
      </SwitchWrapper>
    </Wrapper>
  );
};

const SaveWrapper = styled("div")({
  borderRadius: "4px",
  padding: "4px 10px",
});

const SaveText = styled(Typography)({
  fontSize: "9.75px",
  fontWeight: "600",
  lineHeight: "13px",
  color: "#F5F5F5",
});

const SwitchText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
});

const AnnualButton = styled<any>(Button)(({ isAnnual, theme }) => ({
  display: "flex",
  gap: "12px",

  borderRadius: "0 6px 6px 0",
  border: "1px solid",
  borderRight: "none",
  borderColor: isAnnual ? "#D166E0" : "#E3DCE4",
  background: isAnnual ? "#D166E0" : "#FFF",

  boxShadow: isAnnual
    ? "0px 2px 16px 0px rgba(130, 29, 144, 0.48) inset"
    : "0px 2px 16px 0px rgba(0, 0, 0, 0.08) inset",

  "& > p": {
    color: isAnnual ? "#F5F5F5" : "#0F0311",
  },

  "& > div": {
    background: !isAnnual ? "#D166E0" : "#F7E6FA",

    p: {
      color: isAnnual ? "#0F0311" : "#F5F5F5",
    },
  },

  "&:hover": {
    backgroundColor: isAnnual ? "#D166E0" : "#E6E6E6",
    borderColor: isAnnual ? "#D166E0" : "#E3DCE4",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "12px 20px",
  },
}));

const MonthlyButton = styled<any>(Button)(({ isAnnual, theme }) => ({
  borderRadius: "6px 0 0 6px",
  border: "1px solid",
  borderLeft: "none",
  borderColor: isAnnual ? "#E3DCE4" : "#D166E0",
  boxShadow: isAnnual
    ? "0px 2px 16px 0px rgba(0, 0, 0, 0.08) inset"
    : "0px 2px 16px 0px rgba(130, 29, 144, 0.48) inset",
  background: isAnnual ? "#FFF" : "#D166E0",

  "& > p": {
    color: isAnnual ? "#0F0311" : "#F5F5F5",
  },

  "&:hover": {
    backgroundColor: isAnnual ? "#E6E6E6" : "#D166E0",
    borderColor: isAnnual ? "#E3DCE4" : "#D166E0",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "12px 20px",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  maxWidth: "1080px",
  width: "100%",
  margin: "0 auto",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "40px",
  },
}));

const SwitchWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "460px",

  "& > *": {
    width: "50%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    order: "2",
  },
}));

export default SubscriptionPlansSubtitle;
