import { Chip, Radio } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";

import { SubscriptionPlan } from "../../../types/subscription";

import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { ArtistIcon, ComposerIcon, ConductorIcon, InfoIcon, VirtuosoIcon } from "../../../components/Icons";

import { useEffect, useState } from "react";
import PlanPackageSlider from "./PlanPackageSlider";
import PlanPackagePros from "./PlanPackagePros";
import { Button } from "@maestro-org/ui-kit";

import { InfoTooltipIcon } from "../../../components/Icons";
import { TiersProps } from "./APIPackages";
import { ActivePlan } from "../Plans";
import { useSelector } from "react-redux";
import { getUser, getTeamOwner } from "../../../redux/reducers/usersReducer";

import { UserRoles } from "../../../types/roles";

const getIcon = {
  [SubscriptionPlan.artist]: <ArtistIcon />,
  [SubscriptionPlan.composer]: <ComposerIcon />,
  [SubscriptionPlan.conductor]: <ConductorIcon />,
  [SubscriptionPlan.virtuoso]: <VirtuosoIcon />,
};

const getPlanLabel = {
  [SubscriptionPlan.artist]: "Artist",
  [SubscriptionPlan.composer]: "Composer",
  [SubscriptionPlan.conductor]: "Conductor",
  [SubscriptionPlan.virtuoso]: "Virtuoso",
};

export interface PackageProps {
  id: string;
  name: SubscriptionPlan;
  credits_per_month: number;
  credits_per_day: number;
  description: string;
  features: string[];
  tiers?: TiersProps;
  project_limit: number;
  throughput_limit: string;
  webhook_limit: number;
}

interface AdditionalProps {
  withSlider?: boolean;
  withDiscount?: boolean;
  isAnnual?: boolean;
  activePlan?: ActivePlan;
  handleChangeActive: (id: string, tier_id?: string) => void;
  isActive?: boolean;
}

const PlanPackage = ({
  id,
  name,
  credits_per_month,
  description,
  features,
  tiers,
  withSlider,
  isAnnual,
  activePlan,
  isActive,
  handleChangeActive,
}: PackageProps & AdditionalProps) => {
  const user = useSelector(getUser);
  const owner = useSelector(getTeamOwner);
  const [sliderValue, setSliderValue] = useState(0);

  const showOwnerData = user.team_id && !(user.team_role_name === UserRoles.OWNER);
  const target = showOwnerData ? owner : user;

  const handleSliderChange = (e: any, newValue: number | number[]) => {
    let newIndex;
    if (Array.isArray(newValue)) {
      newIndex = newValue[0];
    } else {
      newIndex = newValue;
    }

    setSliderValue(newIndex);

    if (activePlan?.plan_name !== "Conductor") return;

    if (isAnnual) {
      const currentTier = tiers?.annual_tiers[newIndex];
      handleChangeActive(id, currentTier?.id);
    } else {
      const currentTier = tiers?.monthly_tiers[newIndex];
      handleChangeActive(id, currentTier?.id);
    }
  };

  useEffect(() => {
    if (sliderValue !== 0) {
      handleSliderChange(null, sliderValue);
    }
  }, [isAnnual, sliderValue]);

  useEffect(() => {
    if (!user || !tiers) return;
    const monthlyIndex = tiers.monthly_tiers.findIndex((elem) => elem.id === target.tier_id);
    const annualIndex = tiers.annual_tiers.findIndex((elem) => elem.id === target.tier_id);

    if (monthlyIndex !== -1) setSliderValue(monthlyIndex);
    if (annualIndex !== -1) setSliderValue(annualIndex);
  }, [user]);

  const calculatePackagePrice = () => {
    if (name === SubscriptionPlan.conductor) {
      if (isAnnual === false && tiers) {
        const discountPrice = tiers.monthly_tiers[sliderValue].price_per_month;
        const defaultPrice = tiers.monthly_tiers[sliderValue].value_per_month;
        const creditsPerMonth = tiers.monthly_tiers[sliderValue].credits_per_month / 1000000;
        const discountPercent = Math.round(((defaultPrice - discountPrice) / defaultPrice) * 1000) / 10;
        const pricePerK = tiers.monthly_tiers[sliderValue].price_per_credit * 100000;
        const isDiscount = discountPrice < defaultPrice;
        const disabled = tiers.monthly_tiers[sliderValue].id === activePlan?.tier_id;
        const tier_id = tiers.monthly_tiers[sliderValue].id;

        return {
          price: `$${Number.isInteger(defaultPrice) ? defaultPrice : defaultPrice.toFixed(2)}`,
          discountPrice: `$${Number.isInteger(discountPrice) ? discountPrice : discountPrice.toFixed(2)}`,
          creditsPerMonth: `${creditsPerMonth}M credits a month`,
          discountPercentage: Math.round(discountPercent),
          pricePerK: Number(pricePerK.toFixed(2)),
          isDiscount: isDiscount,
          chipText: "Pay-as-you-go",
          tooltipText:
            "Commit to a minimum amount of credits per month and unlock incrementally higher volume discounts. Any credits consumed above that is pay-as-you-go.",
          disabled: disabled,
          buttonText: "Get Started",
          tier_id: tier_id,
        };
      } else if (isAnnual === true && tiers) {
        const discountPrice = tiers.annual_tiers[sliderValue].price_per_year / 12;
        const defaultPrice = tiers.annual_tiers[sliderValue].value_per_month;
        const creditsPerMonth = tiers.annual_tiers[sliderValue].credits_per_month / 1000000;
        const discountPercent = Math.round(((defaultPrice - discountPrice) / defaultPrice) * 1000) / 10;
        const pricePerK = tiers.annual_tiers[sliderValue].price_per_credit * 100000;
        const isDiscount = discountPrice < defaultPrice;
        const disabled =
          // tiers.annual_tiers[sliderValue].id === activePlan?.tier_id;
          tiers.annual_tiers[sliderValue].id === activePlan?.tier_id;
        const tier_id = tiers.annual_tiers[sliderValue].id;

        return {
          price: `$${Number.isInteger(defaultPrice) ? defaultPrice : defaultPrice.toFixed(2)}`,
          discountPrice: `$${Number.isInteger(discountPrice) ? discountPrice : discountPrice.toFixed(2)}`,
          creditsPerMonth: `${creditsPerMonth}M credits a month`,
          discountPercentage: Math.round(discountPercent),
          pricePerK: Number(pricePerK.toFixed(2)),
          isDiscount: isDiscount,
          chipText: "Pay-as-you-go",
          tooltipText:
            "Commit to a minimum amount of credits per month and unlock incrementally higher volume discounts. Any credits consumed above that is pay-as-you-go.",
          disabled: disabled,
          buttonText: "Get Started",
          tier_id: tier_id,
        };
      }
    }
    if (name === SubscriptionPlan.artist) {
      const creditsPerMonth = credits_per_month / 1000000;
      const disabled = activePlan?.plan_name === SubscriptionPlan.artist;
      return {
        discountPrice: "Free",
        creditsPerMonth: `${creditsPerMonth}M credits a month`,
        tooltipText:
          "Plan is capped at 33,333 credits/day (~1M/mo) and renews daily. Unused credits are not rolled over.",
        disabled: disabled,
        buttonText: "Start for Free",
      };
    }
    if (name === SubscriptionPlan.virtuoso) {
      const disabled = activePlan?.plan_name === SubscriptionPlan.virtuoso;
      return {
        discountPrice: "Contact us",
        creditsPerMonth: "Customized discount",
        tooltipText: "Benefit from specialized discounts tailored to your needs and scale your usage cost-effectively.",
        disabled: disabled,
        buttonText: "Contact Us",
      };
    }
  };

  const handleButtonClick = () => {
    handleChangeActive(id, calculatePackagePrice()?.tier_id);
  };

  return (
    <PackageWrapper isActive={calculatePackagePrice()?.disabled} onClick={handleButtonClick}>
      {calculatePackagePrice()?.chipText && (
        <MostPopular color="custom" hexColor="#FFFFFF" label={calculatePackagePrice()?.chipText} />
      )}

      <Top>
        <TitleWrapper>
          <WithIcon>
            {getIcon[name]}
            <Typography color="textColor.dark" variant="subtitle1">
              {getPlanLabel[name]}
            </Typography>
          </WithIcon>
          <StyledRadio checked={isActive} value="" label="" handleChange={console.log} />
        </TitleWrapper>
        <PriceWrapper>
          {calculatePackagePrice()?.isDiscount && (
            <Discount variant="chartTitle">{calculatePackagePrice()?.price}</Discount>
          )}
          <Typography
            color={name === SubscriptionPlan.virtuoso ? "textColor.contrastText" : "textColor.dark"}
            variant="chartTitle"
          >
            {calculatePackagePrice()?.discountPrice}
          </Typography>

          {calculatePackagePrice()?.isDiscount && (
            <SaveWrapper>
              <SaveText>SAVE {calculatePackagePrice()?.discountPercentage}%</SaveText>
            </SaveWrapper>
          )}
        </PriceWrapper>
        <CreditsWrapper>
          <CreditsPerMonth color="textColor.lightGray">{calculatePackagePrice()?.creditsPerMonth}</CreditsPerMonth>
          <StyledTooltip enterTouchDelay={0} title={<TooltipText>{calculatePackagePrice()?.tooltipText}</TooltipText>}>
            <InfoIconWrapper>
              <InfoTooltipIcon />
            </InfoIconWrapper>
          </StyledTooltip>
        </CreditsWrapper>
      </Top>
      <PlanInfoWrapper>
        {withSlider && (
          <PlanPackageSlider
            sliderValue={sliderValue}
            handleSliderChange={handleSliderChange}
            price={calculatePackagePrice()?.pricePerK}
          />
        )}
        <PackageDividerWrapper>
          <Divider color="light" />
        </PackageDividerWrapper>
        <PlanPackagePros pros={features} />
      </PlanInfoWrapper>
    </PackageWrapper>
  );
};

const Discount = styled(Typography)({
  color: "#CCC",
  textDecoration: "line-through",
});

const CreditsPerMonth = styled(Typography)({
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "24px",
});

const SaveWrapper = styled("div")({
  borderRadius: "4px",
  padding: "3px 10px",
  background: "#C070DD",
  marginBottom: "auto",
});

const SaveText = styled(Typography)({
  fontSize: "9.75px",
  fontWeight: "600",
  lineHeight: "13px",
  color: "#fff",
});

const PriceWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  minHeight: "34px",
});

const TooltipText = styled(Typography)(({ theme }) => ({
  color: "#333333",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
}));

const PackageWrapper = styled("div")<{ isActive?: boolean }>(({ theme, isActive }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "32px",
  borderRadius: theme.borderRadius.sm,
  boxShadow: "0px 2px 12px rgba(153, 153, 153, 0.12)",
  border: isActive ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[100]}`,
  background: theme.palette.common.white,
  minWidth: "377px",
  transition: "border-color 0.3s",
  minHeight: "640px",

  [theme.breakpoints.down("sm")]: {
    height: "auto",
    minHeight: "unset",
  },
}));

const StyledRadio = styled(Radio)({
  padding: 0,
  cursor: "default",
});

const MostPopular = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "8px 20px",
  borderRadius: "53px",
  border: "1px solid #E6E6E6",
  cursor: "default",

  "&:hover": {
    color: "#0F0311",
  },

  fontWeight: 300,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#0F0311",
}));

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const PlanInfoWrapper = styled("div")({
  flex: 1,
  paddingBottom: "32px",
});

const CreditsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const InfoIconWrapper = styled("div")({
  height: "16px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
    background: "#FFFFFF",
  },
}));

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "16px",

  "& .MuiFormControlLabel-root": {
    margin: 0,
  },
});

const WithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",

  "& svg": {
    width: "32px",
    height: "32px",
  },
});

const PackageDividerWrapper = styled("div")({
  padding: "20px 0",
});

const ActionButton = styled(Button)(({ theme }) => ({
  width: "100%",
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "24px",

  "&:disabled": {
    color: "#DE91E9",
  },
}));

export default PlanPackage;
