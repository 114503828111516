import React, { FC } from "react";
import Divider from "../../../components/Divider/Divider";
import Slider from "../../../components/Slider/Slider";
import { styled, Typography } from "@mui/material";
import { InfoTooltipIcon } from "../../../components/Icons";
import Tooltip from "../../../components/Tooltip/Tooltip";

interface Props {
  sliderValue: number;
  handleSliderChange: any;
  price?: number;
}

const PlanPackageSlider: FC<Props> = ({ sliderValue, handleSliderChange, price }) => {
  return (
    <div>
      <PackageDividerWrapper>
        <Divider color="light" />
      </PackageDividerWrapper>
      <InnerWrapper>
        <Row>
          <LeftText color="textColor.contrastText">Monthly Credits</LeftText>
          <Left>
            <RightText color="textColor.lightGray">$ {price} per 100k</RightText>
            <StyledTooltip
              enterTouchDelay={0}
              title={
                <TooltipText>
                  Cost per 100,000 credits. Commit to higher amount of credits per month and unlock larger discounts.
                </TooltipText>
              }
            >
              <InfoIconWrapper>
                <InfoTooltipIcon />
              </InfoIconWrapper>
            </StyledTooltip>
          </Left>
        </Row>
        <SliderWrapper>
          <Slider defaultValue={0} value={sliderValue} onChange={handleSliderChange} marks={true} step={1} max={6} />
        </SliderWrapper>
      </InnerWrapper>
    </div>
  );
};

const SliderWrapper = styled("div")({
  "& .MuiSlider-root": {
    overflow: "visible",
  },

  "& .MuiSlider-mark": {
    width: "16px",
    height: "16px",
    background: "#E6E6E6",
    opacity: 1,
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
  },

  "& .MuiSlider-markActive": {
    background: "#D166E0",
    color: "#D166E0",
  },

  "& .MuiSlider-rail": {
    backgroundColor: "#E6E6E6 !important",
    color: "#E6E6E6",
  },

  "& .MuiSlider-track": {
    background: "#D166E0",
    color: "#D166E0",
  },

  "& .MuiSlider-thumb": {
    color: "#D166E0",
    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    width: "12px !important",
    height: "12px !important",
    background: "#C53DD8 !important",
    boxShadow: "0px 0px 0px 5px #EBBBF1 !important",
  },

  // "& .MuiSlider-thumb.Mui-active": {
  //   width: "12px !important",
  //   height: "12px !important",
  //   background: "#C53DD8 !important",
  //   boxShadow: "0px 0px 0px 5px #EBBBF1 !important",
  // },
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
    background: "#FFFFFF",
  },
}));

const TooltipText = styled(Typography)(({ theme }) => ({
  color: "#333333",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
}));

const InfoIconWrapper = styled("div")({
  height: "16px",
});

const LeftText = styled(Typography)({
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "400",
});

const RightText = styled(Typography)({
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Left = styled("div")({
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

const InnerWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const PackageDividerWrapper = styled("div")({
  padding: "20px 0",
});

export default PlanPackageSlider;
