import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { blockchainLabels, Network, networkLabels } from "../../types/project";
import { CreateProjectFields } from "./types";

import { Option } from "../../types/newEntry";

export const initialValuesCreateProject = {
  [CreateProjectFields.name]: "",
  [CreateProjectFields.network]: [],
  [CreateProjectFields.blockchain]: [],
};

// prettier-ignore
const networkOptions: Option[] = Object.values(networkLabels).map(label => ({
  value: label,
  disabled: false,
}));

// prettier-ignore
const blockchainOptions: Option[] = Object.values(blockchainLabels).map(label => ({
  value: label,
  disabled: false,
}));

export const getCreateProjectFields: NewEntryField[] = [
  {
    type: NewEntryFieldTypes.text,
    name: CreateProjectFields.name,
    placeholder: "Project Name",
    fieldLabel: "Project Name",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateProjectFields.blockchain,
    placeholder: "Blockchain",
    options: blockchainOptions,
    fieldLabel: "Blockchain",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateProjectFields.network,
    placeholder: "Network",
    options: networkOptions,
    fieldLabel: "Network",
  },
];
