import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";

import { getSubscriptionPlan } from "../../../../redux/reducers/authReducer";

import Divider from "../../../../components/Divider/Divider";

import { SubscriptionPlan } from "../../../../types/subscription";
import { UserSubscriptions } from "../../../../types/user";
import { CheckoutSteps } from "../../../../types/settings";
import { NewActivePlan, ActivePlan } from "../../Plans";

interface Props {
  handleChangeStep: (step?: CheckoutSteps) => void;
  activePlan?: NewActivePlan;
  currentPlan?: ActivePlan;
}

const OrderSummaryMobile = ({ handleChangeStep, activePlan, currentPlan }: Props) => {
  const getCreditsPerDay = () => {
    if (activePlan) {
      if (activePlan.activePlan.name === SubscriptionPlan.virtuoso) {
        return (
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              Pay-as-you-go
            </Typography>
            <Typography color="grey.A200" variant="paragraphSmall">
              Unlimited
            </Typography>
          </Row>
        );
      }
      if (activePlan.activePlan.name === SubscriptionPlan.artist) {
        return (
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              Total compute credits
            </Typography>
            <Typography color="grey.A200" variant="paragraphSmall">
              {`${Math.floor(+activePlan.activePlan.credits_per_day / 1000)}k/day`}
            </Typography>
          </Row>
        );
      }
      if (activePlan.activePlan.name === SubscriptionPlan.conductor && activePlan.tier) {
        return (
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              Total compute credits
            </Typography>
            <Typography color="grey.A200" variant="paragraphSmall">
              {`${Math.floor(activePlan.tier?.credits_per_month / 30 / 1000)}k/day`}
            </Typography>
          </Row>
        );
      }
    }
  };

  return (
    <Wrapper>
      <Typography color="grey.A200" variant="h6">
        Order summary
      </Typography>
      <PlanInfoWrapper>
        <PlanInfoItem>
          {activePlan && activePlan.tier ? (
            <Row>
              <Typography color="primary.main" variant="h6">
                Price per credit
              </Typography>
              <Typography color="primary.main" variant="h6">
                ${+activePlan.tier.price_per_credit}
              </Typography>
            </Row>
          ) : (
            <Row>
              <Typography color="primary.main" variant="h6">
                Price per month
              </Typography>
              <Typography color="primary.main" variant="h6">
                {activePlan?.activePlan.name === SubscriptionPlan.virtuoso
                  ? "Contact us"
                  : `${activePlan?.tier ? activePlan.tier.price_per_month.toFixed(2) : "Free"}`}
              </Typography>
            </Row>
          )}
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              API Package
            </Typography>
            <PlanName color="grey.A200" variant="paragraphSmall">
              {activePlan?.activePlan.name}
            </PlanName>
          </Row>
          {getCreditsPerDay()}
        </PlanInfoItem>
        <Divider />
      </PlanInfoWrapper>
      <ButtonWrapper>
        {activePlan?.activePlan.name === SubscriptionPlan.virtuoso ? (
          <Ref href="mailto:info@gomaestro.org">
            <Typography color="primary.main" variant="h5">
              Contact us
            </Typography>
          </Ref>
        ) : (
          <Actions>
            <BackButton onClick={() => handleChangeStep()}>Back</BackButton>
            <Button
              disabled={activePlan?.activePlan.name === currentPlan?.plan_name}
              onClick={() => handleChangeStep(CheckoutSteps.checkout)}
            >
              Checkout
            </Button>
          </Actions>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
});

const PlanInfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
});

const PlanInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PlanName = styled(Typography)({
  textTransform: "capitalize",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Actions = styled("div")({
  display: "flex",
  justifyContent: "space-between",

  "& > button": {
    width: "calc(50% - 16px)",
  },
});

const BackButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

const Ref = styled("a")(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
}));

const BottomInfo = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "12px",
});

export default OrderSummaryMobile;
