import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EmptyTeamIcon } from "../../../../components/Icons/EmptyTeamIcon";
import { UserPlusIcon } from "../../../../components/Icons/UserPlusIcon";
import { updatePopup } from "../../../../redux/actions/popupsActions";
import { Popups } from "../../../../types/popups";

import Tooltip from "../../../../components/Tooltip/Tooltip";

import { SubscriptionPlan } from "../../../../types/subscription";
import { getSubscriptionPlan } from "../../../../redux/reducers/authReducer";

const EmptyTeamCase = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(getSubscriptionPlan);

  const isArtist = subscription === SubscriptionPlan.artist;

  const handleOpenCreateTeamDialog = () => {
    dispatch(updatePopup({ popup: Popups.createTeam, status: true }));
  };

  return (
    <Wrapper>
      <IconWrapper>
        <EmptyTeamIcon />
      </IconWrapper>

      <Title>Welcome to Your Team Page!</Title>
      <Subtitle>It looks like you haven&apos;t created a team yet. Start by creating your Team.</Subtitle>

      <StyledTooltip
        title={
          <Typography variant="paragraphSmall" color="grey.A200">
            You have to upgrade your subscription to use Teams.
          </Typography>
        }
        disableHoverListener={!isArtist}
      >
        <ButtonWrapper>
          <StyledButton onClick={handleOpenCreateTeamDialog} disabled={isArtist}>
            <UserPlusIcon />
            <p>Create Team</p>
          </StyledButton>
        </ButtonWrapper>
      </StyledTooltip>
    </Wrapper>
  );
};

const StyledButton = styled(Button)({
  display: "flex",
  gap: "8px",
  alignItems: "center",

  padding: "8px 16px",
  borderRadius: "8px",

  svg: {
    fill: "none !important",
  },

  p: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",

    color: "#F5F5F5",
  },

  "&:hover": {
    svg: {
      fill: "none",
    },
  },
});

const Subtitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",

  color: "#B3B3B3",
});

const Title = styled(Typography)({
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "28px",

  color: "#000000",
});

const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "64px",
  width: "64px",

  backgroundColor: "#F5F5F5",
  borderRadius: "50%",
});

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",

  gap: "8px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  zIndex: "100",
  "& .MuiTooltip-tooltip": {
    maxWidth: "216px",
    padding: "16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
  },
}));

const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "32px",
  alignItems: "center",
});

export default EmptyTeamCase;
