import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getIsAuthorized } from "../redux/reducers/authReducer";

import { PathElement } from "../types/breadcrumpsPathTypes";
import { SettingsPages } from "../types/settings";

import SettingsNavigation from "../components/Navigation/SettingsNavigation";
import SettingsSidebar from "../modules/Settings/components/SettingsSidebar";
import { DocumentationIcon } from "../components/Icons";

import { pages } from "../lib/routeUtils";
import { getUser, getTeamOwner } from "../redux/reducers/usersReducer";
import { updateSubscriptionPlan } from "../redux/actions/authActions";
import { getBillingDataServer } from "../redux/actions/stripeActions";
import { getNewSubscriptions } from "../redux/reducers/stripeReducer";
import { getUserServer, getTeamOwnerServer } from "../redux/actions/userActions";
import Breadcrumps from "../components/Breadcrumps/Breadcrumps";
import Footer from "../modules/Home/components/Footer";
import { getCurrentPlanName } from "../lib/plan";
import { UserRoles } from "../types/roles";

import { AccountIcon } from "../components/Icons/AccountIcon";
import { TeamIcon } from "../components/Icons/TeamIcon";
import { BillingIcon } from "../components/Icons/BillingIcon";
import { SubscriptionIcon } from "../components/Icons/SubscriptionIcon";

interface Props {
  children: React.ReactNode;
  active: SettingsPages;
  path: PathElement[];
}

const SettingsLayout = ({ children, active, path }: Props) => {
  const newSubscriptions = useSelector(getNewSubscriptions);
  const user = useSelector(getUser);
  const isAuthorized = useSelector(getIsAuthorized);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const owner = useSelector(getTeamOwner);

  const showOwnerData = user.team_id && !(user.team_role_name === UserRoles.OWNER);

  const target = showOwnerData ? owner : user;

  // prettier-ignore
  const filteredSidebarItems = sidebarItems.filter((item) => {
    if (
      user.team_id &&
    (
        (item.title === "Subscription" &&
         user.team_role_name !== UserRoles.OWNER &&
         user.team_role_name !== UserRoles.ADMIN) ||
        (item.title === "Billing" && 
         user.team_role_name !== UserRoles.OWNER && 
         user.team_role_name !== UserRoles.ADMIN)
      )
    ) {
      return false;
    }
    return true;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const newSubscriptionName = getCurrentPlanName(newSubscriptions, target);
    dispatch(updateSubscriptionPlan({ plan: newSubscriptionName }));
  }, [user, owner]);

  useEffect(() => {
    if (!user || !isFirstRender) return;
    dispatch(getBillingDataServer());
    setIsFirstRender(false);
  }, [user]);

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getUserServer());
    dispatch(getTeamOwnerServer());
  }, [isAuthorized]);

  return (
    <>
      <OuterWrapper>
        <SettingsSidebar active={active} items={filteredSidebarItems} />
        <Wrapper>
          <SettingsNavigation path={path} actions={<SettingsNavActions />} />
          <Content>
            <BreadcrumpsWrapper>
              <Breadcrumps path={path} />
              <SettingsNavActions />
            </BreadcrumpsWrapper>
            {children}
            <MobileOnly>
              <FooterWrapper>
                <Footer />
              </FooterWrapper>
            </MobileOnly>
          </Content>
        </Wrapper>
      </OuterWrapper>
    </>
  );
};

const OuterWrapper = styled("div")({
  display: "flex",
  height: "100vh",
  overflowY: "hidden",
});

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflowY: "hidden",
  flexGrow: 1,
  minWidth: 0,
});

const Content = styled("div")(({ theme }) => ({
  background: theme.palette.grey[50],
  minWidth: 0,
  display: "flex",
  padding: "56px 0 50px 284px",
  flexDirection: "column",
  rowGap: "56px",
  flexGrow: 1,
  overflow: "hidden auto",
  paddingBottom: "110px",

  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: "8px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  [theme.breakpoints.down("xl")]: {
    padding: "30px 0 30px 150px",
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "100px",
  },

  [theme.breakpoints.down(1101)]: {
    padding: "24px",
  },

  [theme.breakpoints.down("sm")]: {
    rowGap: "48px",
    paddingBottom: "0",
  },
}));

const BreadcrumpsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.up(1101)]: {
    display: "none",
  },

  [theme.breakpoints.down("sm")]: {
    "& > div": {
      flexWrap: "wrap",
    },
  },
}));

export const sidebarItems = [
  {
    title: "Account",
    icon: <AccountIcon />,
    pages: [
      { value: SettingsPages.accountDetails, href: pages.account(), title: "Account details" },
      // { value: SettingsPages.credentials, href: pages.credentials(), title: "Credentials" },
    ],
  },
  {
    title: "Team",
    icon: <TeamIcon />,
    pages: { value: SettingsPages.team, href: pages.team() },
  },
  {
    title: "Subscription",
    icon: <SubscriptionIcon />,
    pages: [
      { value: SettingsPages.overview, href: pages.subscriptionPlan(), title: "Overview" },
      { value: SettingsPages.plans, href: pages.settings(), title: "Plans" },
    ],
  },
  {
    title: "Billing",
    icon: <BillingIcon />,
    pages: { value: SettingsPages.billing, href: pages.billing() },
  },
];

const SettingsNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <DesktopOnly>
          <Typography variant="paragraphMedium" color="textColor.dark">
            Documentation
          </Typography>
        </DesktopOnly>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const MobileOnly = styled("div")(({ theme }) => ({
  marginTop: "auto",

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const FooterWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.grey[50],
}));

export default SettingsLayout;
