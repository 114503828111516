import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUser, getTeamOwner } from "../../redux/reducers/usersReducer";
import {
  getBillingInvoicesServer,
  getSubscriptionServicesServer,
  getUserServer,
  getTeamOwnerServer,
} from "../../redux/actions/userActions";
import { getIsAuthorized, getSubscriptionPlan } from "../../redux/reducers/authReducer";

import SubscriptionPlansLayout from "../../layouts/SubscriptionPlansLayout";

import APIPackages from "./components/APIPackages";

import { SettingsPages } from "../../types/settings";
import withPrivateRoute from "../../hooks/withPrivateRoute";
import { getProjectsServer } from "../../redux/actions/projectsActions";
import { getWebhooksServer } from "../../redux/actions/webhooksActions";
import { SubscriptionPlan } from "../../types/subscription";
import { PackageProps } from "./components/PlanPackage";
import {
  getNewSubscriptions,
  getAllSubscriptionTiers,
  getAllSubscriptionFeatures,
} from "../../redux/reducers/stripeReducer";
import {
  getSubscriptionsServer,
  getSubscriptiontTiers,
  getSubscriptionFeatures,
} from "../../redux/actions/stripeActions";
import { AnnualProps, MonthlyProps } from "./components/APIPackages";
import Addons from "./components/Addons";

import { UserRoles } from "../../types/roles";

const path = [
  { title: "Settings", href: "/" },
  { title: "Subscription", href: "/" },
  { title: "Plans", href: "/" },
];

export interface ActivePlan {
  plan_name: string;
  tier_id?: string;
}

export interface NewActivePlan {
  activePlan: PackageProps;
  tier?: AnnualProps | MonthlyProps;
  isAnnual?: boolean;
}

const Plans = () => {
  const dispatch = useDispatch();

  const [activePlan, setActivePlan] = useState<NewActivePlan>();

  const [permanentCurrentUserPlan, setPermanentCurrentUserPlan] = useState<ActivePlan>();
  const [currentUserPlan, setCurrentUserPlan] = useState<ActivePlan>();
  const user = useSelector(getUser);
  const subscriptionPlan = useSelector(getSubscriptionPlan);
  const isAuthorized = useSelector(getIsAuthorized);
  const allSubscriptionPlans = useSelector(getNewSubscriptions);
  const allSubscriptionTiers = useSelector(getAllSubscriptionTiers);
  const features = useSelector(getAllSubscriptionFeatures);

  const owner = useSelector(getTeamOwner);

  const showOwnerData = user.team_id && !(user.team_role_name === UserRoles.OWNER);
  const target = showOwnerData ? owner : user;

  const handleChangeActivePlan = (planId: string, tierId?: string) => {
    const currentPlan = allSubscriptionPlans.find(({ id }) => planId === id);
    if (!currentPlan) return;

    if (currentPlan.name === SubscriptionPlan.conductor && allSubscriptionTiers) {
      let currentTier: AnnualProps | MonthlyProps | undefined;
      if (tierId) {
        currentTier = allSubscriptionTiers.annual_tiers.find(({ id }) => tierId === id);
        if (!currentTier) {
          currentTier = allSubscriptionTiers.monthly_tiers.find(({ id }) => tierId === id);
        }
      }

      if (currentTier) {
        setActivePlan({
          activePlan: currentPlan,
          tier: currentTier,
          isAnnual: "price_per_year" in currentTier,
        });
        setCurrentUserPlan({ plan_name: currentPlan.name, tier_id: currentTier?.id });
      }
    } else {
      setActivePlan({ activePlan: currentPlan });
      setCurrentUserPlan({ plan_name: currentPlan.name });
    }
  };

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getSubscriptionServicesServer());
    dispatch(getUserServer());
    dispatch(getTeamOwnerServer());
    dispatch(getBillingInvoicesServer());
    dispatch(getProjectsServer({}));
    dispatch(getWebhooksServer({}));
    dispatch(getSubscriptionsServer());
    dispatch(getSubscriptiontTiers());
    dispatch(getSubscriptionFeatures());
  }, [isAuthorized]);

  useEffect(() => {
    if (user && subscriptionPlan) {
      const newCurrentSubscription = {
        plan_name: subscriptionPlan,
        tier_id: target.tier_id,
      };
      setCurrentUserPlan(newCurrentSubscription);
      setPermanentCurrentUserPlan(newCurrentSubscription);
    }
  }, [user, owner]);

  return (
    <SubscriptionPlansLayout
      path={path}
      active={SettingsPages.plans}
      activePlan={activePlan}
      permanentPlan={permanentCurrentUserPlan}
      currentPlan={currentUserPlan}
    >
      <Wrapper>
        <APIPackages
          activePlan={currentUserPlan}
          handleChangeActive={handleChangeActivePlan}
          permanentPlan={permanentCurrentUserPlan}
        />
        <Addons activePlan={currentUserPlan} services={features} />
        {/* <ScalingPolicy
          packages={packages}
          activePlan={activePlan}
          activeScalingPolicy={activeScalingPolicy}
          handleChangeActive={handleChangeScalingPolicy}
          scalingPolicyCards={scalingPolicyCards}
        /> */}
      </Wrapper>
    </SubscriptionPlansLayout>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "64px",
  paddingBottom: "108px",
});

export default withPrivateRoute(Plans);
