import React from "react";
import { Slider as MuiSlider, SliderProps, styled, Typography } from "@mui/material";

const Slider = ({ ...props }: SliderProps) => {
  return (
    <Wrapper>
      <StyledSlider {...props} />
      {Number.isFinite(props.max) && Number.isFinite(props.min) && (
        <CaptionWrapper>
          <Typography color="textColor.dark">{props.min}</Typography>
          <Typography color="textColor.dark">{props.max}</Typography>
        </CaptionWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "4px",
});

const CaptionWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledSlider = styled(MuiSlider)(({ theme, min, max }) => ({
  color: "#C53DD8",

  "& .MuiSlider-thumb": {
    width: "16px",
    height: "16px",
  },

  "& .MuiSlider-thumb:hover": {
    boxShadow: `none`,
  },

  "& .Mui-active": {
    boxShadow: `0px 0px 0px 4px #EBBBF1 !important`,
  },

  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#C7C7C7",
  },

  "& .MuiSlider-valueLabel": {
    background: "transparent",
    color: "#000000",
  },
}));

export default Slider;
