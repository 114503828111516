import { StripeItem } from "../../types/auth";
import { StripeCustomer } from "../../types/stripeCustomer";

export const GET_BILLING_DATA_SERVER = "GET_BILLING_DATA_SERVER";
export const UPDATE_BILLING_DATA = "UPDATE_BILLING_DATA";
export const UPDATE_BILLING_DATA_LOADING = "UPDATE_BILLING_DATA_LOADING";
export const UPDATE_CURRENT_PAYMENT_METHOD = "UPDATE_CURRENT_PAYMENT_METHOD";
export const ADD_PAYMENT_SERVER = "ADD_PAYMENT_SERVER";
export const UPDATE_CLIENT_SECRET = "UPDATE_CLIENT_SECRET";
export const SET_PAYMENT_SERVER = "SET_PAYMENT_SERVER";
export const DELETE_PAYMENT_SERVER = "DELETE_PAYMENT_SERVER";
export const UPDATE_LOADING_PAYMENT_METHODS = "UPDATE_LOADING_PAYMENT_METHODS";
export const UPDATE_USER_FIRST_SUBSCRIPTION_SERVER = "UPDATE_USER_FIRST_SUBSCRIPTION_SERVER";
export const UPDATE_USER_SUBSCRIPTION_SERVER = "UPDATE_USER_SUBSCRIPTION_SERVER";
export const CHANGE_SUBSCRIPTION_UPDATED = "CHANGE_SUBSCRIPTION_UPDATED";
export const GET_CUSTOMER_SERVER = "GET_CUSTOMER_SERVER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_LOADING = "UPDATE_CUSTOMER_LOADING";
export const GET_NEW_USER_SUBSCRIPTIONS_SERVER = "GET_NEW_USER_SUBSCRIPTIONS_SERVER";
export const UPDATE_SUBSCRIPTIONS = "UPDATE_SUBSCRIPTIONS";
export const UPDATE_SUBSCRIPTIONS_LOADING = "UPDATE_SUBSCRIPTIONS_LOADING";
export const GET_SUBSCRIPTION_FEATURES_SERVER = "GET_SUBSCRIPTION_FEATURES_SERVER";
export const UPDATE_SUBSCRIPTION_FEATURES = "UPDATE_SUBSCRIPTION_FEATURES";
export const UPDATE_SUBSCRIPTION_FEATURES_LOADING = "UPDATE_SUBSCRIPTION_FEATURES_LOADING";
export const GET_SUBSCRIPTION_TIERS_SERVER = "GET_SUBSCRIPTION_TIERS_SERVER";
export const UPDATE_SUBSCRIPTION_TIERS = "UPDATE_SUBSCRIPTION_TIERS";
export const UPDATE_SUBSCRIPTION_TIERS_LOADING = "UPDATE_SUBSCRIPTION_TIERS_LOADING";
export const GET_USER_CURRENT_SUBSCRIPTION = "GET_USER_CURRENT_SUBSCRIPTION";
export const UPDATE_USER_CURRENT_SUBSCRIPTION = "UPDATE_USER_CURRENT_SUBSCRIPTION";
export const UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING = "UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING";

interface UpdateBillingDataProps {
  paymentMethods: StripeItem[];
}

interface UpdateStatusProps {
  status: boolean;
}

interface UpdateActivePaymentMethodProps {
  id: string;
}

interface UpdateClientSecretProps {
  clientSecret: string;
}

interface SetDefaultPaymentMethod {
  methodId: string;
  withoutToast?: boolean;
}

interface DeletePaymentMethod {
  methodId: string;
  loadingMethods: string[];
  newActiveId?: string;
  customerId: string;
}

interface UpdateLoadingMethods {
  methodsIds: string[];
}

interface UpdateUserSubscriptionProps {
  package_id: string;
  payment_method_id: string;
  tier_id: string;
}

interface SubscriptionStatusUpdate {
  isUpdated: boolean;
  success: boolean;
}

interface UpdateStripeCustomer {
  customer: StripeCustomer;
}

export const getBillingDataServer = () => ({
  type: GET_BILLING_DATA_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/billing/methods",
    },
  },
});

export const updateBillingData = (data: UpdateBillingDataProps) => ({
  type: UPDATE_BILLING_DATA,
  payload: { data },
});

export const updateBillingDataLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_BILLING_DATA_LOADING,
  payload: { status },
});

export const updateActivePaymentMethod = ({ id }: UpdateActivePaymentMethodProps) => ({
  type: UPDATE_CURRENT_PAYMENT_METHOD,
  payload: { id },
});

export const addPaymentServer = () => ({
  type: ADD_PAYMENT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/billing/add",
      data: {},
    },
  },
});

export const updateClientSecret = ({ clientSecret }: UpdateClientSecretProps) => ({
  type: UPDATE_CLIENT_SECRET,
  payload: { clientSecret },
});

export const setPaymentServer = ({ methodId, withoutToast = false }: SetDefaultPaymentMethod) => ({
  type: SET_PAYMENT_SERVER,
  payload: {
    request: {
      method: "PUT",
      url: "/billing/set",
      data: { default_payment_method_id: methodId },
    },
    withoutToast,
  },
});

export const deletePaymentServer = ({ methodId, loadingMethods, customerId, newActiveId }: DeletePaymentMethod) => ({
  type: DELETE_PAYMENT_SERVER,
  payload: {
    request: {
      method: "DELETE",
      url: "/billing/delete",
      data: { payment_method_id: methodId },
    },
    loadingMethods,
    methodId,
    customerId,
    newActiveId,
  },
});

export const updateLoadingMethods = ({ methodsIds }: UpdateLoadingMethods) => ({
  type: UPDATE_LOADING_PAYMENT_METHODS,
  payload: { methodsIds },
});

export const updateFirstSubscriptionServer = (data: UpdateUserSubscriptionProps) => ({
  type: UPDATE_USER_FIRST_SUBSCRIPTION_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/subscription",
      data,
    },
  },
});

export const updateUserCurrentSubscription = (data: any) => ({
  type: UPDATE_USER_CURRENT_SUBSCRIPTION,
  payload: data,
});

export const getUserCurrentSubscriptionLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING,
  payload: status,
});

export const getSubscriptionsServer = () => ({
  type: GET_NEW_USER_SUBSCRIPTIONS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/subscription/packages",
    },
  },
});

export const getSubscriptionsServerLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_SUBSCRIPTIONS_LOADING,
  payload: status,
});

export const updateSubscriptions = (data: any) => ({
  type: UPDATE_SUBSCRIPTIONS,
  payload: data,
});

export const getSubscriptionFeatures = () => ({
  type: GET_SUBSCRIPTION_FEATURES_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/subscription/features",
    },
  },
});

export const updateSubscriptionFeatures = (data: any) => ({
  type: UPDATE_SUBSCRIPTION_FEATURES,
  payload: data,
});

export const getSubscriptionFeaturesLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_SUBSCRIPTION_FEATURES_LOADING,
  payload: status,
});

export const getSubscriptiontTiers = () => ({
  type: GET_SUBSCRIPTION_TIERS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/subscription/tiers",
    },
  },
});

export const updateSubscriptionTiers = (data: any) => ({
  type: UPDATE_SUBSCRIPTION_TIERS,
  payload: data,
});

export const getSubscriptionTiersLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_SUBSCRIPTION_TIERS_LOADING,
  payload: status,
});

export const chageSubscriptionUpdated = (data: SubscriptionStatusUpdate) => ({
  type: CHANGE_SUBSCRIPTION_UPDATED,
  payload: { data },
});

export const getCustomerServer = () => ({
  type: GET_CUSTOMER_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/billing/customer",
    },
  },
});

export const updateCustomer = ({ customer }: UpdateStripeCustomer) => ({
  type: UPDATE_CUSTOMER,
  payload: { customer },
});

export const updateCustomerLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_CUSTOMER_LOADING,
  payload: { status },
});
