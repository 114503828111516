import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";

import { getSubscriptionPlan } from "../../../redux/reducers/authReducer";

import Divider from "../../../components/Divider/Divider";
import { InfoIcon } from "../../../components/Icons";

import { SubscriptionPlan } from "../../../types/subscription";
import { UserSubscriptions } from "../../../types/user";
import { NewActivePlan, ActivePlan } from "../Plans";

interface Props {
  handleCheckoutClick: () => void;
  activePlan?: NewActivePlan;
  permanentPlan?: ActivePlan;
  currentPlan?: ActivePlan;
}

const OrderSummary = ({ handleCheckoutClick, activePlan, permanentPlan, currentPlan }: Props) => {
  const getCreditsPerDay = () => {
    if (activePlan) {
      if (activePlan.activePlan.name === SubscriptionPlan.virtuoso) {
        return (
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              Pay-as-you-go
            </Typography>
            <Typography color="grey.A200" variant="paragraphSmall">
              Unlimited
            </Typography>
          </Row>
        );
      }
      if (activePlan.activePlan.name === SubscriptionPlan.artist) {
        return (
          <>
            <Row>
              <Typography color="grey.A200" variant="paragraphSmall">
                Compute Credits
              </Typography>
              <Typography color="grey.A200" variant="paragraphSmall">
                {`${Math.floor(+activePlan.activePlan.credits_per_month / 1000000)}M/mo`}
              </Typography>
            </Row>
            <Row>
              <Typography color="grey.A200" variant="paragraphSmall">
                Credit Rate
              </Typography>
              <Typography color="grey.A200" variant="paragraphSmall">
                N/A
              </Typography>
            </Row>
          </>
        );
      }
      if (activePlan.activePlan.name === SubscriptionPlan.conductor && activePlan.tier) {
        return (
          <>
            <Row>
              <Typography color="grey.A200" variant="paragraphSmall">
                Compute Credits
              </Typography>
              <Typography color="grey.A200" variant="paragraphSmall">
                {`${Math.floor(activePlan.tier?.credits_per_month / 1000000)}M/mo`}
              </Typography>
            </Row>
            <Row>
              <Typography color="grey.A200" variant="paragraphSmall">
                Credit Price
              </Typography>
              <Typography color="grey.A200" variant="paragraphSmall">
                {`$${Number(activePlan.tier?.price_per_credit * 100000).toFixed(2)}/100k`}
              </Typography>
            </Row>
          </>
        );
      }
    }
  };

  return (
    <Wrapper>
      <Typography color="grey.A200" variant="h6">
        Order summary
      </Typography>
      <PlanInfoWrapper>
        <PlanInfoItem>
          {activePlan && activePlan.tier ? (
            <Row>
              <Typography color="primary.main" variant="h6">
                {activePlan.isAnnual ? `Price per year` : `Price per month`}
              </Typography>
              <Typography color="primary.main" variant="h6">
                {activePlan.isAnnual && `price_per_year` in activePlan.tier
                  ? `$${activePlan.tier.price_per_year.toFixed(2)}`
                  : `$${activePlan.tier?.price_per_month.toFixed(2)}`}
              </Typography>
            </Row>
          ) : (
            <Row>
              {activePlan?.activePlan.name !== SubscriptionPlan.virtuoso && (
                <Typography color="primary.main" variant="h6">
                  Price per month
                </Typography>
              )}
              <Typography color="primary.main" variant="h6">
                {activePlan?.activePlan.name === SubscriptionPlan.virtuoso
                  ? "Contact us"
                  : `${activePlan?.tier ? activePlan.tier.price_per_month.toFixed(2) : "Free"}`}
              </Typography>
            </Row>
          )}
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              API Package
            </Typography>
            <PlanName color="grey.A200" variant="paragraphSmall">
              {activePlan?.activePlan.name}
            </PlanName>
          </Row>
          {getCreditsPerDay()}
        </PlanInfoItem>
        <Divider />
      </PlanInfoWrapper>
      {/* {activePlan && !!+activePlan.price_per_credit && (
        <BottomInfo>
          <InfoIcon />
          <Typography color="grey.300" variant="article">
            Subscription changes take effect immediately and previous plan terms no longer apply.
          </Typography>
        </BottomInfo>
      )} */}
      <ButtonWrapper>
        {activePlan?.activePlan.name === SubscriptionPlan.virtuoso ? (
          <Ref href="mailto:info@gomaestro.org">
            <Typography color="primary.main" variant="h5">
              Contact us
            </Typography>
          </Ref>
        ) : (
          <Button
            disabled={
              activePlan?.activePlan.name === SubscriptionPlan.conductor
                ? currentPlan?.tier_id === permanentPlan?.tier_id
                : currentPlan?.plan_name === permanentPlan?.plan_name
            }
            onClick={handleCheckoutClick}
          >
            Checkout
          </Button>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
});

const PlanInfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const PlanInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PlanName = styled(Typography)({
  textTransform: "capitalize",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Ref = styled("a")(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
}));

const BottomInfo = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "12px",
});

export default OrderSummary;
