import { styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "@maestro-org/ui-kit";

import { getSubscriptionServicesLoading } from "../../../redux/reducers/usersReducer";

import PlanSettingsCard, { CardTypes } from "./PlanSettingsCard";
import { BlockchainIndexerIcon, TransactionMonitoringIcon, TurboTXIcon } from "../../../components/Icons";

import { SubscriptionService, UserSubscriptions, SubscriptionFeature } from "../../../types/user";
import { TransactionMonitoringPlanIcon } from "../../../components/Icons/TransactionMonitoringPlanIcon";
import { ManagedSmartContracts } from "../../../components/Icons/ManagedSmartContracts";
import { PriceFeedsIcon } from "../../../components/Icons/PriceFeedsIcon";
import { ProjectMetricsPlanIcon } from "../../../components/Icons/ProjectMetricsPlanIcon";
import { WalletManagerIcon } from "../../../components/Icons/WalletManagerIcon";
import { NewActivePlan, ActivePlan } from "../Plans";

interface Props {
  activePlan?: ActivePlan;
  services?: SubscriptionFeature[];
}

const Addons = ({ activePlan, services }: Props) => {
  const isLoading = useSelector(getSubscriptionServicesLoading);

  return (
    <Wrapper>
      <Typography variant="h5" color="grey.A200">
        Package features
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <CardsWrapper>
          {!!services?.length &&
            services.map((service) => (
              <PlanSettingsCard
                type={CardTypes.Expand}
                key={service.name}
                disabled={!activePlan || !service.packages.includes(`${activePlan.plan_name}`)}
                availableIn={service.packages}
                title={service.name}
                description={service.details}
                icon={getIcon[service.name as keyof typeof getIcon]}
                blockchains={service.chains}
              />
            ))}
        </CardsWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const CardsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  rowGap: "20px",

  "& > div": {
    width: "calc(50% - 18px)",
  },

  [theme.breakpoints.down("lg")]: {
    "& > div": {
      width: "100%",
    },
  },

  [theme.breakpoints.down(1101)]: {
    "& > div": {
      width: "calc(50% - 18px)",
    },
  },

  [theme.breakpoints.down("sm")]: {
    "& > div": {
      width: "100%",
    },
  },
}));

const getIcon = {
  ["Blockchain Indexer API"]: <BlockchainIndexerIcon />,
  ["Transaction Manager"]: <TransactionMonitoringIcon />,
  ["Turbo Transactions"]: <TurboTXIcon />,
  ["Transaction Monitoring"]: <TransactionMonitoringPlanIcon />,
  ["Managed Smart Contracts"]: <ManagedSmartContracts />,
  ["Market Price Feeds"]: <PriceFeedsIcon />,
  ["Project Metrics"]: <ProjectMetricsPlanIcon />,
  ["Wallet Manager"]: <WalletManagerIcon />,
};

export default Addons;
