import React, { FC } from "react";
import { styled, Typography } from "@mui/material";

interface Props {
  pros?: string[];
}

const PlanPackagePros: FC<Props> = ({ pros }) => {
  return (
    <PackageDetailsWrapper>
      {pros &&
        pros.map((elem) => (
          <ItemWrapper key={elem}>
            <Typography color="contrastText.main">✓</Typography>
            <PackageDetailsItem>
              <Typography color="textColor.dark">{elem}</Typography>
            </PackageDetailsItem>
          </ItemWrapper>
        ))}
    </PackageDetailsWrapper>
  );
};

const PackageDetailsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const PackageDetailsItem = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const ItemWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "5px",
});

export default PlanPackagePros;
