import React from "react";
import { Button } from "@maestro-org/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { styled, Typography } from "@mui/material";

import {
  getActiveMethodId,
  getAllPaymentMethods,
  getBillingDataLoading,
} from "../../../../redux/reducers/stripeReducer";
import { setPaymentServer } from "../../../../redux/actions/stripeActions";

import Divider from "../../../../components/Divider/Divider";
import PaymentMethod from "../PaymentMethod";
import { InfoIcon } from "../../../../components/Icons";
import { CheckoutSteps } from "../../../../types/settings";
import { Link } from "react-router-dom";
import { pages } from "../../../../lib/routeUtils";
import { UserSubscriptions } from "../../../../types/user";
import { SubscriptionPlan } from "../../../../types/subscription";
import { NewActivePlan } from "../../Plans";

interface Props {
  handleChangeStep: (step?: CheckoutSteps) => void;
  activePlan?: NewActivePlan;
}

const CheckoutMobile = ({ handleChangeStep, activePlan }: Props) => {
  const dispatch = useDispatch();

  const handleBackClick = () => handleChangeStep(CheckoutSteps.orderSummary);
  const handlePayClick = () => handleChangeStep(CheckoutSteps.waitingForPayment);

  const paymentMethods = useSelector(getAllPaymentMethods);
  const activePaymentMethodId = useSelector(getActiveMethodId);
  const loadingBillingData = useSelector(getBillingDataLoading);

  const handleChangeActive = (id: string) => () => {
    dispatch(setPaymentServer({ methodId: id }));
  };

  return (
    <Wrapper>
      <CheckoutTitleWrapper>
        <Typography variant="h5" color="grey.A200">
          Checkout
        </Typography>
        <Typography color="grey.A200" variant="paragraphSmall">
          Select your payment method to finish your order
        </Typography>
      </CheckoutTitleWrapper>
      <Divider />
      <PaymentMethodWrapper>
        <Row>
          <Typography color="grey.A200" variant="paragraphMedium">
            Payment method
          </Typography>
          <Link to={pages.addNewPayment()}>
            <SectionActionButton>
              <Typography color="primary.main" variant="paragraphSmall">
                + Add new
              </Typography>
            </SectionActionButton>
          </Link>
        </Row>
        {loadingBillingData && <div>Loading...</div>}
        {!loadingBillingData && paymentMethods.length ? (
          <PaymentMethodsList>
            {paymentMethods.map((item) => (
              <PaymentMethod
                key={item.id}
                last4={item.card.last4}
                expMonth={item.card.expMonth}
                expYear={item.card.expYear}
                brand={item.card.brand}
                handleDelete={console.log}
                handleMakeDefault={handleChangeActive(item.id)}
                isActive={item.id === activePaymentMethodId}
                isCheckout
              />
            ))}
          </PaymentMethodsList>
        ) : (
          <Typography color="grey.500" variant="paragraphSmall">
            No Payment methods created yet. Please click the "Add New" button.
          </Typography>
        )}
      </PaymentMethodWrapper>
      <Bottom>
        <BottomInfo>
          <div>
            <InfoIcon />
          </div>
          <Typography color="grey.300" variant="article">
            Subscription changes take effect immediately and previous plan terms no longer apply.
          </Typography>
        </BottomInfo>
        <ButtonWrapper>
          <BackButton onClick={handleBackClick}>
            <Typography variant="paragraphSmall" color="grey.A200">
              Back
            </Typography>
          </BackButton>
          <Button onClick={handlePayClick} disabled={!paymentMethods.length}>
            <Typography variant="paragraphSmall">
              {activePlan?.activePlan.name === SubscriptionPlan.artist ? "Select Plan" : "Pay now"}
            </Typography>
          </Button>
        </ButtonWrapper>
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
});

const CheckoutTitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const Bottom = styled("div")({
  paddingTop: "32px",
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
});

const BottomInfo = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "12px",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",

  "& button": {
    width: "calc(50% - 16px)",
  },
});

const BackButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],

  "&:hover": {
    background: theme.palette.grey["50"],
  },
}));

const PaymentMethodWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const PaymentMethodsList = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  paddingTop: "8px",
});

const SectionActionButton = styled(Button)(({ theme }) => ({
  background: "transparent",
  padding: "3px 4px",

  "&:hover": {
    background: "transparent",
  },

  "& path": {
    stroke: theme.palette.primary.main,
  },
}));

const BillingInfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const BillingInfoItemWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
});

const BillingInfoItemName = styled(Typography)({
  width: "142px",
});

// const CheckoutFieldsWrapper = styled("div")({
//   display: "flex",
//   flexDirection: "column",
//   rowGap: "20px",
// });

// const CardEndAdornment = styled("div")({
//   position: "absolute",
//   top: "12.5px",
//   right: "24px",
//   display: "flex",
//   alignItems: "center",
//   columnGap: "10px",
// });

export default CheckoutMobile;
