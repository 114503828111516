import { IBillingInfo, ICard, StripeItem } from "../../types/auth";
import { FeaturesProps, TiersProps } from "../../modules/Settings/components/APIPackages";
import { StripeCustomer } from "../../types/stripeCustomer";
import {
  CHANGE_SUBSCRIPTION_UPDATED,
  UPDATE_BILLING_DATA,
  UPDATE_BILLING_DATA_LOADING,
  UPDATE_CLIENT_SECRET,
  UPDATE_CURRENT_PAYMENT_METHOD,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_LOADING,
  UPDATE_LOADING_PAYMENT_METHODS,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS_LOADING,
  UPDATE_SUBSCRIPTION_FEATURES,
  UPDATE_SUBSCRIPTION_FEATURES_LOADING,
  UPDATE_SUBSCRIPTION_TIERS,
  UPDATE_SUBSCRIPTION_TIERS_LOADING,
  UPDATE_USER_CURRENT_SUBSCRIPTION,
  UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING,
} from "../actions/stripeActions";
import { StoreType } from "../types/store.types";
import { SubscriptionPlan } from "../../types/subscription";

export interface Package {
  id: string;
  name: SubscriptionPlan;
  description: string;
  credits_per_day: number;
  credits_per_month: number;
  project_limit: number;
  webhook_limit: number;
  throughput_limit: string;
  features: string[];
}

export interface stripeStateType {
  isLoading: boolean;
  paymentMethods: StripeItem[];
  activeMethodId: string;
  clientSecret: string;
  loadingMethodsIds: string[];
  subscriptionUpdated: {
    isUpdated: boolean;
    success: boolean;
  };
  customer?: StripeCustomer;
  customerLoading: boolean;
  newSubscriptions: Package[];
  isSubscriptionsLoading: boolean;
  subscriptionFeatures: FeaturesProps[];
  isSubscriptionFeaturesLoading: boolean;
  subscriptionTiers: TiersProps | undefined;
  isSubscriptionTiersLoading: boolean;
  userCurrentSubscription: any | undefined;
  isUserCurrentSubscriptionLoading: boolean;
}

const stripeInitialState: stripeStateType = {
  isLoading: true,
  paymentMethods: [],
  activeMethodId: "",
  clientSecret: "",
  loadingMethodsIds: [],
  subscriptionUpdated: {
    isUpdated: false,
    success: false,
  },
  customer: undefined,
  customerLoading: false,
  newSubscriptions: [],
  isSubscriptionsLoading: true,
  subscriptionFeatures: [],
  isSubscriptionFeaturesLoading: true,
  subscriptionTiers: undefined,
  isSubscriptionTiersLoading: true,
  userCurrentSubscription: undefined,
  isUserCurrentSubscriptionLoading: true,
};

const stripeReducer = (state = stripeInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_BILLING_DATA: {
      return {
        ...state,
        paymentMethods: [...action.payload.data.paymentMethods],
      };
    }
    case UPDATE_BILLING_DATA_LOADING: {
      return {
        ...state,
        isLoading: action.payload.status,
      };
    }
    case UPDATE_CURRENT_PAYMENT_METHOD: {
      return {
        ...state,
        activeMethodId: action.payload.id,
      };
    }
    case UPDATE_CLIENT_SECRET: {
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
      };
    }
    case UPDATE_LOADING_PAYMENT_METHODS: {
      return {
        ...state,
        loadingMethodsIds: action.payload.methodsIds,
      };
    }
    case CHANGE_SUBSCRIPTION_UPDATED: {
      return {
        ...state,
        subscriptionUpdated: action.payload.data,
      };
    }
    case UPDATE_CUSTOMER: {
      return {
        ...state,
        customer: action.payload.customer,
      };
    }
    case UPDATE_CUSTOMER_LOADING: {
      return {
        ...state,
        customerLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTIONS: {
      return {
        ...state,
        newSubscriptions: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTIONS_LOADING: {
      return {
        ...state,
        isSubscriptionsLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTION_FEATURES: {
      return {
        ...state,
        subscriptionFeatures: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION_FEATURES_LOADING: {
      return {
        ...state,
        isSubscriptionFeaturesLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTION_TIERS: {
      return {
        ...state,
        subscriptionTiers: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION_TIERS_LOADING: {
      return {
        ...state,
        isSubscriptionTiersLoading: action.payload.status,
      };
    }
    case UPDATE_USER_CURRENT_SUBSCRIPTION: {
      return {
        ...state,
        userCurrentSubscription: action.payload,
      };
    }
    case UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING: {
      return {
        ...state,
        isUserCurrentSubscriptionLoading: action.payload.status,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getAllPaymentMethods = (state: StoreType) => state.stripe.paymentMethods;
export const getBillingDataLoading = (state: StoreType) => state.stripe.isLoading;
export const getActiveMethodId = (state: StoreType) => state.stripe.activeMethodId;
export const getClientSecret = (state: StoreType) => state.stripe.clientSecret;
export const getLoadingPaymentMethods = (state: StoreType) => state.stripe.loadingMethodsIds;
export const getIsSubscriptionUpdated = (state: StoreType) => state.stripe.subscriptionUpdated;
export const getStripeCustomer = (state: StoreType) => state.stripe.customer;
export const getStripeCustomerLoading = (state: StoreType) => state.stripe.customerLoading;
export const getNewSubscriptions = (state: StoreType) => state.stripe.newSubscriptions;
export const getAllSubscriptionsLoading = (state: StoreType) => state.stripe.isSubscriptionsLoading;
export const getAllSubscriptionFeatures = (state: StoreType) => state.stripe.subscriptionFeatures;
export const getAllSubscriptionFeaturesLoading = (state: StoreType) => state.stripe.isSubscriptionFeaturesLoading;
export const getAllSubscriptionTiers = (state: StoreType) => state.stripe.subscriptionTiers;
export const getAllSubscriptionTiersLoading = (state: StoreType) => state.stripe.isSubscriptionTiersLoading;
export const getNowUserCurrentSubscription = (state: StoreType) => state.stripe.userCurrentSubscription;
export const getNowUserCurrentSubscriptionLoading = (state: StoreType) => state.stripe.isUserCurrentSubscriptionLoading;

export default stripeReducer;
