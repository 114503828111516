import { styled, Typography } from "@mui/material";
import { Loader } from "@maestro-org/ui-kit";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSubscriptionPlan } from "../../../redux/reducers/authReducer";
import { getUserSubscriptionsLoading } from "../../../redux/reducers/usersReducer";

import PlanPackage from "./PlanPackage";

import { UserSubscriptions } from "../../../types/user";
import { SubscriptionPlan } from "../../../types/subscription";

import SubscriptionPlansSubtitle from "./SunscriptionPlansSubtitle";
import { getSubscriptionServicesServer } from "../../../redux/actions/userActions";
import {
  getSubscriptionFeatures,
  getSubscriptionsServer,
  getSubscriptiontTiers,
} from "../../../redux/actions/stripeActions";
import {
  getNewSubscriptions,
  getAllSubscriptionFeatures,
  getAllSubscriptionTiers,
  getNowUserCurrentSubscription,
} from "../../../redux/reducers/stripeReducer";
import { getUserServer, getTeamOwnerServer } from "../../../redux/actions/userActions";
import { getUser, getTeamOwner } from "../../../redux/reducers/usersReducer";

import Tooltip from "../../../components/Tooltip/Tooltip";
import { InfoTooltipIcon } from "../../../components/Icons";
import { ActivePlan } from "../Plans";
import { CompareIcon } from "../../../components/Icons/CompareIcon";

import { UserRoles } from "../../../types/roles";

interface Props {
  activePlan?: ActivePlan;
  handleChangeActive: (planId: string, tierId?: string) => void;
  permanentPlan?: ActivePlan;
}

export interface FeaturesProps {
  chains: string[];
  details: string[];
  name: string;
  packages: string[];
}

export interface AnnualProps {
  credits_per_month: number;
  id: string;
  name: string;
  price_per_credit: number;
  price_per_year: number;
  price_per_month: number;
  value_per_month: number;
}

export interface MonthlyProps {
  credits_per_month: number;
  id: string;
  name: string;
  price_per_credit: number;
  price_per_month: number;
  value_per_month: number;
}

export interface TiersProps {
  annual_tiers: AnnualProps[];
  monthly_tiers: MonthlyProps[];
}

const APIPackages = ({ activePlan, handleChangeActive, permanentPlan }: Props) => {
  const dispatch = useDispatch();

  const [isAnnual, setIsAnnual] = useState<boolean>();

  const plan = useSelector(getSubscriptionPlan);
  const isLoading = useSelector(getUserSubscriptionsLoading);
  const handleChange = (isAnnual: boolean) => () => setIsAnnual(isAnnual);

  const allSubscriptionPlans = useSelector(getNewSubscriptions);
  const allSubscriptionTiers = useSelector(getAllSubscriptionTiers);
  const user = useSelector(getUser);

  const owner = useSelector(getTeamOwner);

  const showOwnerData = user.team_id && !(user.team_role_name === UserRoles.OWNER);
  const target = showOwnerData ? owner : user;

  useEffect(() => {
    dispatch(getSubscriptionFeatures());
    dispatch(getSubscriptionsServer());
    dispatch(getSubscriptiontTiers());
    dispatch(getSubscriptionServicesServer());
    dispatch(getUserServer());
    dispatch(getTeamOwnerServer());
  }, []);

  const getPlanLabel = () => {
    if (
      plan === SubscriptionPlan.conductor &&
      allSubscriptionTiers?.annual_tiers.find(({ id }) => permanentPlan?.tier_id === id)
    ) {
      return {
        label: "Conductor (annual)",
      };
    } else {
      return {
        label: plan,
      };
    }
  };

  useEffect(() => {
    if (permanentPlan?.tier_id) {
      const annualTier = allSubscriptionTiers?.annual_tiers.find(({ id }) => permanentPlan.tier_id === id);
      const monthlyTier = allSubscriptionTiers?.monthly_tiers.find(({ id }) => permanentPlan.tier_id === id);

      if (annualTier) {
        setIsAnnual(true);
      } else if (monthlyTier) {
        setIsAnnual(false);
      }
    } else {
      setIsAnnual(false);
    }
  }, [permanentPlan, allSubscriptionTiers]);

  return (
    <Wrapper>
      <TitleOutterWrapper>
        <TitleWrapper>
          <Typography color="grey.A200" variant="h5">
            API Packages
          </Typography>
          <TextWrapper>
            <Typography color="grey.A200" variant="paragraphSmall">
              Currently subscribed to <PlanName>{getPlanLabel().label}</PlanName>
            </Typography>
          </TextWrapper>
        </TitleWrapper>
        <CompareWrapper href="https://www.gomaestro.org/pricing#plan-comparison" target="_blank">
          <CompareText color="textColor.contrastText">Compare Plans</CompareText>
          <CompareIcon />
        </CompareWrapper>
      </TitleOutterWrapper>
      <SubscriptionPlansSubtitle isAnnual={isAnnual} handleChange={handleChange} />
      {isLoading ? (
        <Loader />
      ) : (
        <Content>
          <PackagesWrapper>
            {allSubscriptionPlans.map((item) => (
              <PlanPackage
                key={item.id}
                isAnnual={isAnnual}
                withSlider={item.name === SubscriptionPlan.conductor}
                features={item.features}
                id={item.id}
                name={item.name}
                credits_per_month={item.credits_per_month}
                tiers={item.name === SubscriptionPlan.conductor ? allSubscriptionTiers : undefined}
                description={item.description}
                activePlan={activePlan}
                handleChangeActive={handleChangeActive}
                credits_per_day={item.credits_per_day}
                throughput_limit={item.throughput_limit}
                webhook_limit={item.webhook_limit}
                project_limit={item.project_limit}
                isActive={item.name === activePlan?.plan_name}
              />
            ))}
          </PackagesWrapper>
          <Fade />
        </Content>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
});

const TitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  position: "relative",
});

const PlanName = styled("strong")({
  textTransform: "capitalize",
});

const TextWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const TitleOutterWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
});

const CompareText = styled(Typography)({
  fontSize: "16px",
});

const CompareWrapper = styled("a")(({ theme }) => ({
  position: "absolute",
  right: "0",
  top: "6px",

  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifySelf: "end",

  [theme.breakpoints.down("md")]: {
    position: "relative",
    display: "flex",
  },
}));

const TooltipText = styled(Typography)({
  color: "#333333",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
});

const InfoIconWrapper = styled("div")({
  height: "16px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
    background: "#FFFFFF",
  },
}));

const Content = styled("div")({
  position: "relative",
});

const PackagesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "36px",
  overflow: "auto hidden",
  padding: "16px 80px 40px 0",
  position: "relative",

  "&::-webkit-scrollbar": {
    height: "2px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "#E6E6E6",
    height: "2px",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#0F0311",
    borderRadius: "2px",
    height: "2px",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  "& > div:last-child": {
    // background: "radial-gradient(83.49% 100% at 50% 100%, #FFFFFF 0%, rgba(247, 229, 250, 0.18) 100%)",
  },

  [theme.breakpoints.down("xl")]: {
    columnGap: "26px",
  },
}));

const Fade = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 5,
  width: "136px",
  background: "linear-gradient(270deg, #F5F5F5 15.15%, rgba(245, 245, 245, 0) 77.52%)",
  pointerEvents: "none",

  [theme.breakpoints.down(1101)]: {
    width: "100px",
  },
}));

export default APIPackages;
